:root {
    --table-header-font-color: #111E47CC;
    --table-header-background: #EFF2FC;
    --table-row-font-color: #a6a6a6;
    --table-row-background-2: #FAFBFB;
    --table-hovered-row: #EFF2FC;

    --three-dots-font-color: #404A6B;

    --subtable-header-background: #EFF2FC; 
    --subtable-row-background: #EFF2FC40;
    --subtable-font-color: #1E3889;

    --main-background: white;
    --main-font-color: #111f47;
    --clickable-button-background: #1E4CDC;
    --clickable-button-font-color: white;
    --non-clickable-button-background: white;
    --non-clickable-button-border-color: #7c849a;
    --non-clickable-button-font-color: #8B8B8B;
    --input-field-bottom-border-color: #b8bcc8;
    --input-field-bottom-border-color-active: #1E3889;

    --footer-paging-border-color: #f1f1f1;
    --footer-paging-light-background: #F8F9FA;

    --sidebar-background: #111f47;
    --sidebar-font-color: #7c849a;
    --sidebar-active-text: white;
    --sidebar-active-subtext-background: rgba(255, 255, 255, 0.05);
    --sidebar-line-color: #767D953D;

    --toast-wrong-background: #DB2B39;


}