@import '../../../styles.css';

.modal-background {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #030229;
    opacity: 0.3;
}

.absolute-add-connectors {
    z-index: 1001;
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 65%;
    border-radius: 10px 0px 0px 10px;
    background-color: var(--main-background);
    overflow: auto;
    padding: 4rem 2rem 2rem 2rem;
}

.horizontal-line-add-connectors {
    width: 95%;
    height: 2px;
    background-color: #f2f2f2;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-top: 1.5rem;
}

.add-connector-span {
    text-align: left;
    font-family: 'Inter-Bold', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: var(--main-font-color);
}

.confirm-addition-span {
    color: var(--sidebar-font-color);
    font-family: 'Inter-Medium', sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.confirm-addition-num-span {
    color: var(--main-font-color);
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.confirm-connectors-btn {
    color: var(--non-clickable-button-font-color);
    border: 1px solid var(--non-clickable-button-border-color);
    border-radius: 10px;
    width: 144px;
    height: 40px;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    cursor: default;
}

.confirm-connectors-btn-active {
    color: var(--clickable-button-font-color);
    border-color: var(--clickable-button-background);
    background-color: var(--clickable-button-background);
    cursor: pointer;
}