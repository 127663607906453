@import "../../../styles.css";

.stopChargingButton {
    border-radius: 10px;
    border-style: solid;
    border-color: var(--non-clickable-button-border-color);
    border-width: 1px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 153px;
    height: 40px;
    position: relative;

    color: var(--non-clickable-button-font-color);
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    cursor: default;
}

.stopChargingButtonActive {
    border: 1px solid var(--clickable-button-background);
    background-color: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    cursor: pointer;
}


.searchInputSessionHistory {
    border-radius: 10px;
    border-style: solid;
    border-color: var(--non-clickable-button-border-color);
    background-color: var(--non-clickable-button-background);
    border-width: 1px;
    height: 40px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
    width: 260px;
    padding: 8px 8px 8px 34px;

}

.charging-sessions-history,
.charging-sessions-history * {
    box-sizing: border-box;
}

.charging-sessions-history {
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 24px;
    font-weight: 600;
    position: relative;
    padding: 5px;
}

.charging-sessions-history-span {
    color: var(--main-font-color);
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 24px;
    font-weight: 600;
}

.charging-sessions-history-span2 {
    color: var(--clickable-button-background);
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 24px;
    font-weight: 600;
}


.confirm {
    padding: 1rem;
    background-color: var(--clickable-button-background);
    cursor: pointer;
    color: var(--clickable-button-font-color);
    border-radius: 0px 0px 0px 10px;
    text-align: center;
    font-family: 'Inter-Medium', sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

.cancel {
    padding: 1rem;
    background-color: var(--footer-paging-light-background);
    cursor: pointer;
    color: var(--main-font-color);
    border-radius: 0px 0px 10px 0px;
    text-align: center;
    font-family: 'Inter-Medium', sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

.popout-main-text {
    color: var(--main-font-color);
    text-align: center;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.popout-other-text {
    color: var(--table-row-font-color);
    text-align: left;
    font-family: 'Inter-Regular', sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.message-container {
    background-color: var(--clickable-button-background);
    border-radius: 0px 0px 10px 10px;
    color: var(--clickable-button-font-color);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    position: fixed;
    top: 0%;
    left: 35%;
    width: 404px;
    height: 43px;
}