.charger-location-line {
    width: inherit;
    border: 2px solid #F2F2F2;
    margin-bottom: 50px;
}

.delete-charger-btn{
    display: flex;
    height: 40px;
    width: 125px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 10px;
    border: 1.5px solid #CDD0D9;
}
.delete-charger-btn-active{
    background: #1E4CDC;
    border: none !important;
}
.delete-charger-btn .delete-text{
    color: #7C849A;
    font-size: 14px;
    font-weight: 500;
}
.delete-charger-btn .delete-text-active{
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
}
.delete-charger-popup{
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.connector-status-container-charger {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-weight: 400;
    text-align: center;
    height: 20px;
    font-size: 12px;
    font-weight: 500;
    padding: 10px 12px;
}
.underline{
    text-decoration: underline;
}
.available-green {
    color: #0AB051;
    background-color: #E6F5EC;
}
.in-use-blue {
    color: #1E4CDC;
    background-color: #EFF2FC;
}
.offline-red {
    color: #ED1F0E;
    background-color: #FDE9E7;
}