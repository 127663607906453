@import '../../../../styles.css';

.finish-onboarding,
.finish-onboarding * {
    box-sizing: border-box;
}

.finish-onboarding {
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 24px;
    font-weight: 600;
    position: relative;
}

.finish-onboarding-span {
    color: var(--main-font-color);
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 24px;
    font-weight: 600;
}

.confirmButton {
    border-radius: 10px;
    border: 1px solid var(--non-clickable-button-border-color);
    background-color: var(--non-clickable-button-background);
    padding: 8px;
    height: 40px;
    width: 170px;
    color: var(--non-clickable-button-font-color);
    text-align: left;
    font-family: 'Inter-Medium', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    position: relative;
}

.confirmButtonActive {
    border: 1px solid var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    background-color: var(--clickable-button-background);
    cursor: pointer;
}

.connector-header {
    background: var(--main-font-color);
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    justify-content: center;
    width: 247px;
    height: 51px;
    position: relative;

    color: var(--clickable-button-font-color);
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    font-weight: 600;
    position: relative;
}

.connector-id {
    background: var(--table-row-background-2);
    border-radius: 10px;
    padding: 20px;
    width: 247px;

    height: 51px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    color: var(--main-font-color);
    text-align: left;
    font-family: 'Inter-Bold', sans-serif;
    font-size: 14px;
    font-weight: 700;
}

.connector-id-input {
    color: var(--main-font-color);
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    padding: 10px 6px 10px 8px;
    border-bottom: 2px solid #f2f2f2;
}

.connector-id-input-readonly {
    color: var(--main-font-color);
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    font-weight: 600;
    outline: none;
    padding: 10px 6px 10px 8px;
    border-bottom: 2px solid #f2f2f2;
}

.connector-id-input:focus {
    border-bottom: 2px solid var(--clickable-button-background);
}