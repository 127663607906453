.forgot-password-main {
    color: var(--main-font-color);
    text-align: left;
    font-family: 'Inter-Bold', sans-serif;
    font-size: 32px;
    font-weight: 700;
}

.enter-your-username {
    color: var(--table-row-font-color);
    text-align: center;
    font-family: 'Inter-Regular', sans-serif;
    font-size: 16px;
    line-height: 130.7%;
    font-weight: 400;
    margin-bottom: 1rem;
}

.login-email-password {
    color: var(--main-font-color);
    text-align: left;
    font-family: 'Inter-Regular', sans-serif;
    font-size: 20px;
    font-weight: 400;
}

.password-reset-button {
    background: var(--clickable-button-background) !important;
    border-radius: 10px;
    padding: 15px 140px 15px 140px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 394px;
    height: 56px;
    position: relative;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: var(--clickable-button-font-color);
    margin-top: 1rem;
}

.login-input {
    color: var(--table-row-font-color);
    text-align: left;
    font-family: 'Inter-Regular', sans-serif;
    font-size: 20px;
    font-weight: 400;
    outline: none;
    padding: 10px 6px 10px 8px;
    border-bottom: 2px solid var(--footer-paging-border-color);
}

.login-input:focus {
    border-bottom: 2px solid var(--non-clickable-button-font-color);
    color: var(--main-font-color);
    font-size: 20px;
    font-weight: 400;
    border-bottom: 2px solid var(--clickable-button-background);
}

.incorrect-mail-cancel {
    width: 100%;
    padding: 1rem;
    background-color: var(--footer-paging-light-background);
    cursor: pointer;
    color: var(--main-font-color);
    border-radius: 0px 0px 10px 10px;
    text-align: center;
    font-family: 'Inter-Medium', sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

.popup-main-text {
    color: var(--main-font-color);
    text-align: center;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.popup-other-text {
    color: var(--table-row-font-color);
    text-align: center;
    font-family: 'Inter-Regular', sans-serif;
    font-size: 14px;
    font-weight: 400;
}