@import "@fontsource/inter"; 
@import "@fontsource/inter/500.css"; 
@import "@fontsource/inter/400.css";
@import "@fontsource/inter/300.css"; 

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Inter;
  background: #F6F6F6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Inter;
}
