@import "../../../styles.css";

.resetButtons {
    border-radius: 10px;
    border-style: solid;
    border-color: var(--non-clickable-button-border-color);
    border-width: 1px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 40px;

    color: var(--non-clickable-button-font-color);
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    cursor: default;
}

.resetButtonsActive {
    border: 1px solid var(--clickable-button-background);
    background-color: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    cursor: pointer;
}

.chargerLogButton {
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: var(--non-clickable-button-border-color);
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 40px;

    color: var(--non-clickable-button-font-color);
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    cursor: default;
}
.chargerLogButtonDisabled{
    border-color: var(--non-clickable-button-border-color);
}

.chargerLogButtonActive {
    background: var(--blue, #1E4CDC);
    color: var(--clickable-button-font-color);
    cursor: pointer;
}

.vertical-line {
    height: 30px;
    width: 2px;
    border-radius: 10px;
    background-color: #B8BCC85C;
}


.operations-remote-management,
.operations-remote-management * {
    box-sizing: border-box;
}

.operations-remote-management {
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 24px;
    font-weight: 600;
    position: relative;
    padding: 5px;
}

.operations-remote-management-span {
    color: var(--main-font-color);
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 24px;
    font-weight: 600;
}

.operations-remote-management-span2 {
    color: var(--clickable-button-background);
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 24px;
    font-weight: 600;
}

.historyOutlineButton {
    border-radius: 10px;
    border-style: solid;
    border-color: var(--non-clickable-button-border-color);
    background-color: var(--non-clickable-button-background);
    border-width: 1px;
    padding: 8px 8px 8px 8px;
    height: 40px;
    width: 190px;
    color: var(--non-clickable-button-font-color);
    text-align: left;
    font-family: 'Inter-Medium', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    position: relative;
}

.subtable-row {
    background-color: var(--main-background) !important;
}

.subtable-tr {
    background-color: var(--subtable-row-background);
}

.subtable-td {
    background-color: var(--subtable-row-background);
    color: var(--subtable-font-color);
    text-align: left;
    font-family: 'Inter-Medium', sans-serif;
    font-size: 12px;
    font-weight: 500;
    padding: 1rem 2rem;
    border-bottom: 3px solid var(--subtable-header-background) !important;
}

.connector-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-weight: 400;
    text-align: center;
    height: 27px;
    padding: 3px 36px;
}

.available {
    color: #0AB051;
    background-color: #E6F5EC;

}
.completed{
    background: rgba(255, 168, 0, 0.20);
    color: #FFA800;
}


.in-use {
    color: #1E4CDC;
    background-color: #EFF2FC;

}

.offline {
    color: #ED1F0E;
    background-color: #FDE9E7;

}

.popout-main-text {
    color: var(--main-font-color);
    text-align: center;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.popout-other-text {
    color: var(--table-row-font-color);
    text-align: left;
    font-family: 'Inter-Regular', sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.message-container {
    background-color: var(--clickable-button-background);
    border-radius: 0px 0px 10px 10px;
    color: var(--clickable-button-font-color);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    position: fixed;
    top: 0%;
    left: 35%;
    width: 404px;
    height: 43px;
}

.subtable-header {
    background-color: var(--subtable-header-background);
    border-color: var(--subtable-header-background);
    color: var(--subtable-font-color);
    text-align: left;
    font-family: 'Inter-Medium', sans-serif;
    font-size: 12px;
    font-weight: 500;
    padding: 1rem 2rem;
}


.operations-remote-btns{
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin: 30px 20px 10px 0px;
}

.info-button{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid #111F47;
    display: flex;
    align-items: center;
    justify-content: center;
}
.info-button .i{
    color: #111F47;
    font-size: 18px;
    font-weight: 600;
}
.info-button:hover {
    border-color: #1E4CDC;
}

.info-button:hover .i {
    color: #1E4CDC;
}