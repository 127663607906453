.createLocationBtn{
    display: flex;
    justify-content: center;
    background-color: var(--clickable-button-background);
    height: 40px;
    width: 210px;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
    gap: 7px;
    font-size: 14px;
    font-weight: 500;
}
.map-container {
    position: relative;
    box-shadow: 8px 10px 18px 18px #00000008;
    height: 100%;
}

.map-container .map-btns{
    position: absolute;
    z-index: 600;
    top: 43px;
    left: 32px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 10px;
    width: 315px;
    padding: 6px 5px;
}
.btns-box_shadow{
    box-shadow: 0px 2px 2.5px 0px rgba(0, 0, 0, 0.10);
}
.map-container .map-btns button{
    display: flex;
    justify-content: center;
    padding: 12px 15px;
    font-size: 12px;
    font-weight: 600;
    transition: transform 0.4s ease, background 0.4s ease, color 0.4s ease;
}

.map-container .map-btns button.active{
    width: 150px;
    border-radius: 10px;
    color: #FFFFFF;
    line-height: normal;
    border: 1.6px solid #111F47;
    background: #111F47;
    transform: translateX(0%);
}

.map-container .map-btns button.inactive {
    width: 150px;
    border-radius: 10px;
    border: 1.6px solid #CDD0D9;
    line-height: normal;
    color: #7C849A;
    transform: translateX(0%);
}
.btn-disabled{
    opacity: 0.7;
}
