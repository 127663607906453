.location-information-container{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.flex-location {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-left: 8px;
}
.location-btn-group{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.flex-location .title {
    color: #111F47;
    font-size: 18px;
    font-weight: 700;
}

.location-information-table {
    width: inherit;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 20px;
}

.flex-location .title-1 {
    color: rgba(17, 30, 71, 0.80);
    font-size: 14px;
    font-weight: 600;
}

.flex-location .info-1 {
    color: #A6A6A6;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}
.editBtnActive {
    display: flex;
    width: 135px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    border-radius: 10px;
    background: var(--blue, #1E4CDC);
    color: #FFFFFF;
}
.editBtn {
    display: flex;
    width: 135px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    border-radius: 10px;
    border: 1.5px solid #CDD0D9;
    color: #7C849A;
}

.location-info-edit-input {
    color: #A6A6A6;
    font-family: Inter;
    width: 340px;
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    outline: none;
    padding: 12px 20px;
    height: 1.7rem;
    border-bottom: 2px solid var(--footer-paging-border-color);
}

.location-info-edit-input-address {
    position: relative;
    color: #A6A6A6;
    font-family: Inter;
    width: 340px;
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    outline: none;
    padding: 12px 20px;
    height: 1.7rem;
    border-bottom: 2px solid var(--footer-paging-border-color);
}

.absolute-options{
    position: absolute;
    top: 414px;
    right: 38px;
}

.location-info-edit-input:focus {
    border-bottom: 2px solid var(--clickable-button-background);
}

.location-line {
    margin-left: 8px;
    width: inherit;
    border: 1px solid #F2F2F2;
}

.charger-information-table {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.delete-location-btn {
    display: flex;
    height: 40px;
    width: 125px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 10px;
    background: #1E4CDC;
}
.delete-text-location {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
}