.modal-background {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.new_location-modal {
    z-index: 1100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 26px;
    position: absolute;
    right: -171px;
    top: 340px;
    width: 400px;
    height: 340px;
    overflow: auto;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background: white;
}
.new_location-modal .content{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.new_location-modal .content .title-x{
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
}
.new_location-modal .content .title-x p{
    font-size: 14px;
    color: #000;
    font-weight: 600;
    line-height: 18px;
}
.new_location-modal .content .inputs{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.new_location-modal .content .inputs input{
    width: inherit;
    display: flex;
    font-size: 12px;
    height: 40px;
    padding: 12px 0px;
    align-items: center;
    border-bottom: 1px solid var(--Gray_03, #F2F2F2);
}
.new_location-modal .content .inputs input::placeholder {
    color: #A6A6A6;
    font-weight: 500;
    font-size: 12px;
}
.new_location-modal .content .inputs .select::placeholder {
    color: #A6A6A6;
}
.new_location-modal .content .inputs input:focus {
    outline: none;
    border-bottom: 1px solid #1E4CDC;
}
.content .confirm-button-container{
    margin-top: 26px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content .confirm-button-container .btn{
    display: flex;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    width: 217px;
    height: 40px;
    padding: 10px;
    border-radius: 10px;
}
.content .confirm-button-container .active {
    background: #1E4CDC;
    color: #FFF;
}
.content .confirm-button-container .disabled {
    background: #EFF2FC;
    color: #111F47;
}

.address-container{
    border-radius: 10px;
    background: #FFF;
    padding: 14px 10px;
    box-shadow: -9px 9px 8px 0px rgba(0, 0, 0,  0.02);
}
.address-options{
    font-size: 12px;
    font-weight: 500;
    background: #FAFBFB;
    cursor: pointer;
    color: #404A6B;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 5px;
}
.address-options:hover{
    background: #EFF2FC;
}
