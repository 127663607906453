.info-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 295px;
    gap: 10px;
    padding: 8px 12px;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 14px 29.6px 0px rgba(0, 0, 0, 0.05);
}
.section{
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.section .section-title{
    color: #404A6B;
    font-size: 10px;
    font-weight: 500;
}
.section .text-container{
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 8px 5px;
    border-radius: 5px;
    background: #FAFBFB;
    height: 33px;
}
.text-container .text{
    color: #A6A6A6;
    font-size: 10px;
    font-weight: 500;
}