@import '../../../../styles.css';

.modal-background {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #030229;
    opacity: 0.3;
}

.absolute-add-charger {
    z-index: 1001;
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 45%;
    border-radius: 10px 0px 0px 10px;
    background-color: var(--main-background);
    overflow: auto;
}

.add-new-charger {
    color: var(--main-font-color);
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
}

.create-charger-input {
    color: #a6a6a6;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    border-style: solid;
    border-color: #f2f2f2;
    border-width: 0px 0px 1px 0px;
    padding: 18px 0px 18px 8px;
    height: 40px;
    outline: none;
}
.create-charger-input-readonly {
    color: #a6a6a6;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    border-style: solid;
    border-color: #f2f2f2;
    border-width: 0px 0px 1px 0px;
    padding: 18px 0px 18px 8px;
    height: 40px;
    outline: none;
}

.create-charger-input:focus {
    border-bottom: 1px solid var(--clickable-button-background);
}

.connect-btn {
    background: var(--table-hovered-row);
    border: 1px solid var(--table-hovered-row);
    border-radius: 10px;
    padding: 20px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 2rem;
}

.connect-btn-active {
    background-color: var(--clickable-button-background);
    cursor: pointer;
}

.connect {
    color: var(--main-font-color);
    font-family: Inter;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

.connect-active {
    color: var(--clickable-button-font-color);
}
