.location-modal-btns {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 10px;
    width: 315px;
    margin-bottom: 10px;
    white-space: nowrap;
}

.location-modal-btns button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px 15px;
    font-size: 12px;
    text-wrap: nowrap;
    height: 38px;
    border-radius: 10px;
    font-weight: 600;
    transition: background-color 0.3s ease-in-out;
    white-space: nowrap;
}

.location-modal-btns button.active {
    color: #FFFFFF;
    border: 1.6px solid #111F47;
    background: #111F47;
}

.location-modal-btns button.inactive {
    border: 1.6px solid #CDD0D9;
    color: #7C849A;
    transform: translateX(0%);
}
.slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    /* Same as button width */
    background-color: #4CAF50;
    /* Slider color, same as active button color */
    transition: transform 0.3s ease-in-out;
    z-index: -1;
    border-radius: 5px;
}


.btn-group{
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 1;
    gap: 15px;
    justify-content: center;
    background: #F2F2F2;
    border-radius: 10px;
    width: 315px;
    height: 40px;
    border: 2px solid #F2F2F2;
    position: relative;
}
.btn-group .btn-one{
    transition: 0.5s;
    cursor: pointer;
}
.btn-group .btn-one span{
    color: #A6A6A6;
    font-size: 12px;
    font-weight: 500;
}
.slider{
    background: #111F47;
    border-radius: 10px;
    height: 37px;
    width: 140px;
    margin-left: -0px;
    position: absolute;
    z-index: -1;
    top: 0;
    transition: 0.5s;
}
.btn-group .btn-two{
    cursor: pointer;
    color: #FFF;
}
.btn-one:focus + .slider{
    margin-left: 200px;
}









