.leaflet-container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.leaflet-top.leaflet-right .leaflet-control-zoom {
    background-color: transparent;
    outline: none;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* Green border */
    border-radius: 5px;
    /* Rounded corners */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    /* Shadow for a 3D effect */
}
.leaflet-right .leaflet-control {
    margin-right: 37px;
}
.leaflet-top .leaflet-control {
    margin-top: 43px;
}

.leaflet-touch .leaflet-bar a {
    width: 35px;
    height: 36px;
    line-height: 40px;
}
.leaflet-control-zoom-in span, .leaflet-control-zoom-out span{
    font-size: 32px;
    color: #111F47;
    font-weight: 200;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
    width: 35px;
    height: 36px;
    background: #FFF;
    color: red;
    font-size: 14px;
}
.marker-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 13px;
}
.circle{
    position: absolute;
    top: 4.5px;
    right: 9.3px;
    width: 27px;
    height: 27px;
    border: 2px solid #222B48;
    border-radius: 50%;
}
.newLocation{
    border: 2px solid #1E4CDC;
    position: absolute;
    top: 4px;
    right: 9.3px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
}

.number-overlay {
    position: absolute;
    top: 34.5%;
    left: 48%;
    height: 20px;
    width: 20px;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    color: #222B48;
    background-color: transparent;
    z-index: 9999;
    box-shadow: none;
}
.newNumber-overlay{
    color: #1E4CDC;
    position: absolute;
    top: 49%;
    left: 48%;
    height: 20px;
    width: 20px;
    transform: translate(-50%, -90%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    background-color: transparent;
    z-index: 9999;
    box-shadow: none;
}
.marker{
    position: relative;
}
.marker:hover .circle,
.marker:hover .number-overlay {
    border-color: #1E4CDC;
    color: #1E4CDC;
    /* Change text color for .number-overlay */
}
.custom-popup{
    width: 200px;
    border-radius: 10px;
    box-shadow: 0px 14px 29.6px 0px rgba(0, 0, 0, 0.05);
}
.custom-popup .upper-part{
    background: #FFF;
    padding: 30px 10px 20px 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.custom-popup .confirm-button{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 13px 10px;
    height: 34px;
    background: #1E4CDC;
    color: #FFF;
}
.custom-popup .confirm-button:hover{
    background: #3459c9;
}
.custom-popup .text{
    color: #111F47;
    font-size: 10px;
    font-family: 'Inter-Bold', sans-serif;
    font-weight: 500;
    text-align: center;
}
