@import '../../styles.css';

	.modal-background {
		z-index: 1000;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #030229;
		opacity: 0.3;
	}

	.popup-container {
		z-index: 9999;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 400px;
		min-width: 350px;
		border-radius: 10px;
		background-color: var(--main-background);
	}

	.popup-text-container {
		padding: 2.6rem;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		gap: 5px;
		background-color: var(--main-background);
		border-radius: 10px 10px 0px 0px;
		margin-bottom: 50px;
	}

    .confirm {
        padding: 1rem;
        background-color: var(--clickable-button-background);
        cursor: pointer;
        color: var(--clickable-button-font-color);
        text-align: center;
        font-family: 'Inter-Medium', sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
    }
    
    .cancel {
        padding: 1rem;
        background-color: var(--footer-paging-light-background);
        cursor: pointer;
        color: var(--main-font-color);
        text-align: center;
        font-family: 'Inter-Medium', sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
    }

	.left-curved {
		border-radius: 0px 0px 0px 10px;
	}

	.right-curved {
		border-radius: 0px 0px 10px 0px;
	}
	
	.bottom-curved {
        border-radius: 0px 0px 10px 10px;
	}