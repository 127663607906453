.table-modal-container{
    margin-top: 30px;
    box-shadow: -9px 9px 8px 0px rgba(0, 0, 0, 0.02);
}
.modal-table-table{
    width: 100%;
}
.modal-table-thead{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 2px solid var(--blue, #1E4CDC);
}
.border-radius-left{
    border-top-left-radius: 10px;
}
.border-radius-right{
    border-top-right-radius: 10px;
}
.modal-table-th{
    color: #3E4869;
    font-size: 14px;
    font-weight: 700;
    padding: 15px;
    background: #FAFBFB;
}
.modal-table-tbody{
    padding: 33px 14px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: -9px 9px 8px 0px rgba(0, 0, 0, 0.02);
}
.modal-table-tr{
    padding: 20px 26px;
    height: 60px;
    border-radius: 10px;
    background: #FAFBFB;
    border-left: none;
}
.selected{
    color: #1E3889;
}
.modal-table-td{
    color: var(--table-row-font-color);
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 15px;
}
.td-selected-row-modal{
    color: #1E3889;
    background: #EFF2FC;
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 15px;
}
.modal-table-tr:hover{
    background: #EFF2FC;
    cursor: pointer;
}