.spinner-container {
    z-index: 1200;
    position: fixed;
    left: 37.5%;
    top: 30%;
    width: 25%;
    height: 30%;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-background {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #030229;
    opacity: 0.3;
}