.create-location-container {
    z-index: 1200;
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 20px;
    right: 0px;
    top: 0px;
    height: 100%;
    border-radius: 10px 0px 0px 10px;
    background-color: var(--main-background);
    overflow: auto;
    transition: width 0.25s ease-in-out;
}

.create-location-container.wide {
    width: 71%;
}

.create-location-container.narrow {
    width: 58%;
}

.content-location-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
}