
.edit-line {
    width: 100%;
    border: 2px solid #F2F2F2;
}



.network-edit-modal {
    z-index: 1100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 26px;
    position: absolute;
    right: -286px;
    top: 395px;
    width: 620px;
    height: 370px;
    overflow: auto;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background: white;
}

.network-edit-modal .edit-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.network-edit-modal .edit-content .edit-title {
    display: flex;
    justify-content: space-between;
}

.network-edit-modal .edit-content .edit-title p {
    font-size: 14px;
    color: #000;
    font-weight: 600;
    line-height: 18px;
}

.network-edit-modal .edit-content .edit-inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 38px !important;
}

.network-edit-input{
    display: flex;
    float: left;
    flex: 2;
    height: 40px;
    padding: 2px 0px;
    align-items: center;
    border-bottom: 2px solid var(--Gray_03, #F2F2F2);
}
.address-input{
    height: 40px;
    padding: 12px 0px;
    border-bottom: 2px solid var(--Gray_03, #F2F2F2);
}
.market-select{
    flex: 4;
}
.edit-inputs .flex-edit-fields{
    display: flex;
    gap: 30px;
    width: 100%;
}


.network-edit-modal .edit-content .edit-inputs input::placeholder {
    color: #A6A6A6;
    font-weight: 500;
    font-size: 12px;
}

.network-edit-modal .edit-content .edit-inputs .select::placeholder {
    color: #A6A6A6;
}

.network-edit-modal .edit-content .edit-inputs input:focus {
    outline: none;
    border-bottom: 1px solid #1E4CDC;
}

.edit-content .confirm-button-container {
    margin-top: 46px !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-content .confirm-button-container .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 217px;
    height: 40px;
    padding: 10px;
    border-radius: 10px;
}

.edit-content .confirm-button-container .active {
    background: #1E4CDC;
    color: #FFF;
}

.edit-content .confirm-button-container .disabled {
    background: #EFF2FC;
    color: #111F47;
}

.address-container {
    border-radius: 10px;
    background: #FFF;
    padding: 14px 10px;
    box-shadow: -9px 9px 8px 0px rgba(0, 0, 0, 0.02);
}

.address-options {
    font-size: 12px;
    font-weight: 500;
    background: #FAFBFB;
    cursor: pointer;
    color: #404A6B;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 5px;
}

.address-options:hover {
    background: #EFF2FC;
}