@import "../../styles.css";

.deleteEditBtn {
    border-radius: 10px;
    border-style: solid;
    border-color: var(--non-clickable-button-border-color);
    border-width: 1px;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 40px;
    width: 135px;

    color: var(--non-clickable-button-font-color);
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    cursor: default;
}

.deleteEditBtnActive {
    border: 1px solid var(--clickable-button-background);
    background-color: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    cursor: pointer;
}

.createNewBtn {
    border-radius: 10px;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 40px;
    width: 144px;

    border: 1px solid var(--clickable-button-background);
    background-color: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    cursor: pointer;
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
}

.pricing-groups,
.pricing-groups * {
    box-sizing: border-box;
}

.pricing-groups {
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 24px;
    font-weight: 600;
    position: relative;
    padding: 5px;
}

.pricing-groups-span {
    color: var(--main-font-color);
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 24px;
    font-weight: 600;
}

.pricing-groups-span2 {
    color: var(--clickable-button-background);
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 24px;
    font-weight: 600;
}

.popout-main-text {
    color: var(--main-font-color);
    text-align: center;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.popout-other-text-remove {
    color: var(--table-row-font-color);
    text-align: center;
    font-family: 'Inter-Regular', sans-serif;
    font-size: 14px;
    font-weight: 400;
}