@import '../styles.css';

header {
    box-shadow: 0px 4px 4px 0px #0000000a;
}

.username {
    color: var(--sidebar-background);
    text-align: left;
    font-family: 'Montserrat-Medium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    align-self: center;
}

.profile-icon {
    width: 44px;
}