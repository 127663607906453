@import '../../styles.css';

.modal-background {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #030229;
    opacity: 0.3;
}

.absolute-edit-company {
    z-index: 1001;
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 35%;
    border-radius: 10px 0px 0px 10px;
    background-color: var(--main-background);
    overflow: auto;
}
.create-market-container{
    display: flex;
    flex-direction: column;
    gap: 45px;
    height: 100%;
}

.user-pic-info-container {
    background-color: var(--main-background);
    color: var(--main-font-color);
    font-family: 'Inter-SemiBold', sans-serif;
    border-radius: 10px;
}

.company-info-container {
    background-color: var(--main-background);
    color: var(--main-font-color);
    font-family: 'Inter-SemiBold', sans-serif;
    border-radius: 10px;
}
.profile-icon-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}
.profile-pic {
    width: 122px;
    margin: 1rem;
}

.name-surname {
    font-size: 24px;
    font-weight: 600;
}

.position {
    font-family: 'Inter-Medium', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: var(--non-clickable-button-font-color);
}


.name-surname-input {
    color: var(--main-font-color);
    font-family: 'Inter-Regular', sans-serif;
    font-size: 11px;
    font-weight: 400;
    height: 1.7rem;
    padding-left: 0.5rem;
    outline: none;
}

.name-surname-input:focus {
    border-bottom: 1px solid var(--clickable-button-background);
}


.super-admin {
    color: var(--Gray_00, #A6A6A6);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.account-input-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.account-input-container .inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.as-input{
    height: 40px;
}
.as-input button{
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
    color: #1E4CDC;
    padding: 10px 0px;

}
.account-input-container input, .as-input {
    width: 215px;
    display: flex;
    height: 40px;
    padding: 10px 0px;
    align-items: center;
    border-bottom: 1px solid var(--Gray_03, #F2F2F2);
}
.w275{
    width: 275px !important;
    
}
.account-input-container input:focus {
    outline: none;
    border-bottom: 1px solid #1E4CDC;
}
.account-input-container input::placeholder {
    color: #222E54;
    font-size: 14px;
    font-weight: 400;
}


.user-info-container {
    width: 100%;
    display: flex;
    align-items: stretch;
    padding: 15px;
    gap: 50px;
}
.user-header {
    color: #A6A6A6;
    font-family: 'Inter-Regular', sans-serif;
    font-size: 14px;
    font-weight: 500;
}
.user-info-container .flex-name{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 18px;
}
.title-btn-container{
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
}
.title-btn-container button{
    display: flex;
    align-items: center;
    cursor: default;
    justify-content: center;
    width: 135px;
    height: 40px;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    color: #7C849A;
    border: 1.5px solid #CDD0D9;
}
.title-btn-container .active {
    background: #1E4CDC;
    color: #FFF;
    cursor: pointer;
}
.user-subtitles {
    color: var(--main-font-color);
    font-size: 20px;
    font-weight: 600;
}

.email-password {
    color: var(--main-font-color);
    font-family: 'Inter-Regular', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.7rem;
}

.change-password {
    text-decoration: underline;
}

.company-info {
    color: #222E54;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;
}

.edit-company-btn {
    border-radius: 10px;
    border: 1px solid var(--non-clickable-button-border-color);
    padding: 10px 20px;
    font-size: 12px;
    height: 44px;
    color: var(--non-clickable-button-font-color);
    line-height: 20px;
    font-weight: 600;
}

.edit-company-btn-active {
    border: 1px solid var(--clickable-button-background);
    background-color: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
    cursor: pointer;
}

.create-market-btn {
    border-radius: 10px;
    background-color: #1E4CDC;
    padding: 10px 20px;
    font-size: 14px;
    color: #FFF;
    line-height: 20px;
    font-weight: 500;
}

.edit-info {
    color: var(--main-font-color);
    font-family: 'Inter-Bold', sans-serif;
    font-size: 18px;
    font-weight: 700;
}

.edit-header {
    color: var(--main-font-color);
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.edit-input {
    color: #111F47;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    padding: 18px 0px !important;
    height: 1.7rem;
    border-bottom: 2px solid var(--footer-paging-border-color);
}

.edit-input:focus {
    border-bottom: 2px solid var(--clickable-button-background);
}
.noBorder:focus{
    border-bottom: 1px solid var(--footer-paging-border-color) !important;
}

.edit-input-readonly {
    color: var(--table-row-font-color);
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    padding-left: 0.5rem;
    height: 1.7rem;
    border-bottom: 2px solid var(--footer-paging-border-color);
}

.confirm-edit-btn {
    background: var(--table-hovered-row);
    border: 1px solid var(--table-hovered-row);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 5rem;
    cursor: pointer;
}

.confirm-edit-btn:hover {
    border: 1px solid var(--main-font-color);
}

.confirm-edit {
    color: var(--main-font-color);
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.visible-passwords {
    width: 21px;
    position: absolute;
    right: 10px;
    top: 2px;
    cursor: pointer;
}

.password-input {
    width: 100%;
}

input[type="password"],
input[type="password"]:focus {
    font-weight: 900;
    letter-spacing: 1.5px;
}

.three-stripes {
    width: 3rem;
    height: 0.25rem;
    border-radius: 10px;
    background-color: var(--footer-paging-border-color);
}

.red {
    background-color: #ED1F0E;
}

.yellow {
    background-color: #F2CE16;
}

.green {
    background-color: #0AB051;
}

.red-font {
    color: #ED1F0E;
}

.yellow-font {
    color: #F2CE16;
}

.green-font {
    color: #0AB051;
}

.confirm-password-btn {
    background: var(--table-hovered-row);
    border: 1px solid var(--table-hovered-row);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-top: 4rem;
}

.confirm-password-btn-active {
    background-color: var(--clickable-button-background);
    cursor: pointer;
}

.confirm-password-active {
    color: var(--clickable-button-font-color);
}

.password-requirements {
    color: var(--table-row-font-color);
    text-align: left;
    font-family: 'Montserrat-Regular', sans-serif;
    font-size: 12px;
    font-weight: 400;
}

.select-country-input {
    border-bottom: 2px solid var(--input-field-bottom-border-color) !important;
    text-align: left !important;
    border-radius: 0px !important;
    min-width: 150px !important;
}