@import '../../../styles.css';


.create-new-pricing-group-btn {
    border-radius: 10px;
    border: 1px solid var(--non-clickable-button-border-color);
    padding: 10px;
    height: 40px;
    width: 144px;
    color: var(--non-clickable-button-font-color);
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    cursor: default;
}

.create-new-pricing-group-btn-active {
    color: var(--clickable-button-font-color);
    border-color: var(--clickable-button-background);
    background-color: var(--clickable-button-background);
    cursor: pointer;
}

.pricing-groups-span {
    color: var(--main-font-color);
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 24px;
    font-weight: 600;
}

.popout-main-text {
    color: var(--main-font-color);
    text-align: center;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.popout-other-text {
    color: var(--table-row-font-color);
    text-align: left;
    font-family: 'Inter-Regular', sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.conn-overview-span {
    color: var(--main-font-color);
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.connnector-overview-add-btn {
    border-radius: 10px;
    border: 1px solid var(--clickable-button-background);
    background-color: var(--clickable-button-background);
    padding: 10px;
    height: 40px;
    color: var(--clickable-button-font-color);
    text-align: center;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    width: 185px;

}

.connnector-overview-remove-btn {
    border-radius: 10px;
    border: 1px solid var(--non-clickable-button-border-color);
    padding: 10px;
    height: 40px;
    color: var(--non-clickable-button-font-color);
    text-align: center;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    width: 185px;
    cursor: default;
}

.connnector-overview-remove-btn-active {
    color: var(--clickable-button-font-color);
    border-color: var(--clickable-button-background);
    background-color: var(--clickable-button-background);
    cursor: pointer;
}

.edit-create-table {
    border-collapse: separate !important;
    width: 100%;
}

.edit-create-header {
    background-color: var(--main-font-color) !important;
    color: var(--clickable-button-font-color);
    padding: 20px;
    margin-bottom: 50px;
}

.edit-create-td {
    background-color: var(--main-background);
    color: var(--main-font-color);
    padding: 20px;
}

.edit-create-input-readonly,
.edit-create-input-readonly:focus {
    background-color: var(--main-background);
    padding: 10px;
    border: none;
    outline: none;
}

.edit-create-input {
    border: none;
    outline: none;
    background-color: var(--main-background);
    border-bottom: 2px solid var(--input-field-bottom-border-color);
    padding: 10px;
    border-radius: 0px;
    width: 15rem;
}

.edit-create-input:focus {
    border: none;
    outline: none;
    border-bottom: 2px solid var(--input-field-bottom-border-color-active);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.horizontal-line {
    width: 95%;
    height: 2px;
    background-color: #f2f2f2;
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-bottom: 2rem;
}

.selected-x-connectors {
    color: #111f47;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.num-of-connectors {
    color: var(--blue, #1e4cdc);
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.eletr-price-input {
    border: none;
    outline: none;
    background-color: var(--main-background);
    width: 10rem;
}

.saveChangesBtn {
    border-radius: 10px;
    padding: 10px 15px;
    height: 40px;
    width: 144px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border: 1px solid var(--non-clickable-button-border-color);
    background-color: var(--non-clickable-button-background);
    color: var(--non-clickable-button-font-color);
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
}

.saveChangesBtnActive {
    border: 1px solid var(--clickable-button-background);
    background-color: var(--clickable-button-background);
    color: var(--clickable-button-font-color);
}