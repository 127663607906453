@import "../../styles.css";

th{
    color: var(--table-header-font-color);
    text-align: left;
    font-family: 'Inter-Bold', sans-serif;
    font-size: 14px;
    font-weight: 700;
    padding: 15px 10px;
    background: rgba(17, 31, 71, 0.02);
    white-space: nowrap;
}

td{
    color: var(--table-row-font-color);
    text-align: left;
    font-family: 'Inter-SemiBold', sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 15px 10px;
}
.td-selected-row {
    color: #1E3889;
}
table{
    border-collapse: collapse;
    border-spacing: 0px;
}
tr:nth-child(2n){
    background-color: var(--table-row-background-2);
} 

.table-container {
    border-radius: 10px;
    background-color: var(--main-background);
    width: 100%;
}

tr {
    background: var(--main-background);
    border-left: 4px solid var(--main-background);
}

.selected-row {

    background-color: var(--table-hovered-row) !important; 
    border-left: 4px solid var(--clickable-button-background);
}

.selectable-row:hover{
    background-color: var(--table-hovered-row);
    cursor: pointer;
}

.checkbox-container {
    background: var(--clickable-button-background);
    border-radius: 5px;
    width: 20px;
    height: 20px;
}

.remove-td {
    width: 55px !important;
}

.selected-row-with-removal {
    background-color: var(--table-hovered-row) !important; 
}

.table-header-with-sort {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.blurred-row {
    display: table-row;
    justify-content: space-between;
    align-items: center;

    background: #f0f0f0;
    border-radius: 5px;
    margin-bottom: 10px;
}

.blurred-col {
    flex: 1;
    height: 10px;
    background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 4px;
    margin: 0 5px;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }

    100% {
        background-position: 200% 0;
    }
}